@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

/* This will only affect elements with the 'custom-scrollbar' class */
.custom-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.custom-scrollbar {
    -ms-overflow-style: none;  /* for Internet Explorer, Edge */
    scrollbar-width: none;  /* for Firefox */
}


.no-scroll {
  overflow: hidden;
}



/* styles.css (or your stylesheet) */
.pulse-dot {
    position: relative;
    display: inline-block;
  }
  
  .pulse-dot::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px; /* Adjust the size of the dot as needed */
    height: 10px; /* Adjust the size of the dot as needed */
    background-color: red;
    border-radius: 50%;
    animation: pulse 1s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  /* styles.css (or your stylesheet) */
.pulse-dot-green {
  position: relative;
  display: inline-block;
}

.pulse-dot-green::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust the size of the dot as needed */
  height: 10px; /* Adjust the size of the dot as needed */
  background-color: lime;
  border-radius: 50%;
  animation: pulse 1s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


  /* styles.css (or your stylesheet) */
  .pulse-dot-orange {
    position: relative;
    display: inline-block;
  }
  
  .pulse-dot-orange::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px; /* Adjust the size of the dot as needed */
    height: 10px; /* Adjust the size of the dot as needed */
    background-color: rgb(167, 7, 34);
    border-radius: 50%;
    animation: pulse 1s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  


  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-scroll {
    display: flex;
    white-space: nowrap;
    animation: scroll 40s linear infinite;
  }
  


  